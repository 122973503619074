


import {h} from 'preact';
import LinksToSocialNetworks from "../../components/LinksToSocialNetworks/LinksToSocialNetworks";
import CasesGrid from "../../components/CasesGrid/CasesGrid";
import FeedbackSlider from "../../components/FeedbackSlider/FeedbackSlider";
import styles from "./style.scss"
import ReturnBack from "./ReturnBack";

const Success = () => {
    return (
        <main id="main-container" className={styles.wrapper} main-attr>
            <ReturnBack></ReturnBack>
            <h1>Информация о международном сотрудничестве</h1>
            <table border={1}>
                <thead>
                <tr>
                    <th style={{width: '40px'}}>№ п/п</th>
                    <th style={{width: '50%'}}>Наименование показателя</th>
                    <th>Наличие</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>1</td>
                    <td>Заключенные и планируемые к заключению договоры с иностранными и (или) международными
                        организациями по вопросам образования и науки
                    </td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>Международная аккредитация образовательных программ</td>
                    <td>-</td>
                </tr>
                </tbody>
            </table>
        </main>
    );
};

export default Success;
