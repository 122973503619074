import { h } from 'preact';
import styles from "./style.scss";

const ContactBlock = ({ blockStyle }) => (
  <></>
  // <div className={`${styles.mediaBlock} ${blockStyle}`}>
  //   <div className={styles.socialMedia}>
  //     <img src="/assets/contacts-page/Telegram.svg" alt="telegram svg" />
  //     <a
  //       href="https://t.me/itrum_team"
  //       target="_blank"
  //       rel="noopener noreferrer"
  //     >
  //       @itrum_team
  //     </a>
  //   </div>
  //   <div className={styles.socialMedia}>
  //     <img
  //       src="/assets/contacts-page/Whatsapp.svg"
  //       alt="whatsapp svg"
  //       className={styles.whatsapp}
  //     />
  //     <a
  //       href="https://wa.me/79910771713"
  //       target="_blank"
  //       rel="noopener noreferrer"
  //     >
  //       @helloitrum
  //     </a>
  //   </div>
  // </div>
);

export default ContactBlock;
