import {h} from 'preact';
import styles from "./style.scss"

const NotFound = () => {
  return (
    <main id="main-container" className={styles.wrapper}>
      <iframe className={styles.iframe} src="/assets/program.pdf" ></iframe>
    </main>
  );
};

export default NotFound;
