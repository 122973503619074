import { h } from "preact";
const Text = ({children}) => children;
import RequestForm from "./RequestForm/RequestForm";
import ContactBlock from "./ContactBlock/ContactBlock";
import styles from "./style.scss";

const Request = () => {

  return (
    <div id="request-form" className={styles.request}>
      <div className={styles.requestHeader}>
         <div className={styles.h1}>
             В ближайшее время мы <div className={styles.titleFree}>запускаем</div>{" "}
            группу обучения веб разработки
         </div>
        <p>
          <Text id="blocks.request.text">
            Заполните форму или свяжитесь с нашим специалистом удобным для вас
            способом. Мы подберем для вас комфортное время консультации и
            расскажем подробнее о проекте
          </Text>
        </p>
        {/* <ContactBlock blockStyle={styles.desktopRequestBottom} /> */}
      </div>
      <RequestForm />
      <ContactBlock blockStyle={styles.mobileRequestBottom} />
    </div>
  );
};

export default Request;
