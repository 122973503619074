import {h} from 'preact';
import Filara from "./Filara/Filara";
import Ukie from "./Ukie/Ukie";
import RealEstate from "./RealEstate/RealEstate";
import ClothingCo from "./ClothingCo/ClothingCo";
import FinPro from "./FinPro/FinPro";
import {doc, root} from "../../helpers/browser_globals";
import styles from "./style.scss";
import {useEffect, useState} from "preact-compat";
import CasePreview from "./CasePreview/CasePreview";

export const startWork = () => {
    const requestForm = doc.querySelector("#request-form");
    const header = doc.querySelector("#header");
    const {top} = requestForm.getBoundingClientRect();
    let isServicesPage = 10;

    const positionForm = Math.floor(root.scrollY + (top - header.clientHeight - isServicesPage));
    root.scrollTo(0, positionForm);
};

const CasesGrid = ({}) => {
    const [dynamicItems, setDynamicItems] = useState([]);
    const loadPortfolio = async () => {
        let data = await fetch('https://construct-api.itconsult-web.ru/api/portfolio');
        data = await data.json();
        data = data.data.items;

        data = data.filter(i => i.info?.published);

        setDynamicItems(data);
    }
    useEffect(() => {
        loadPortfolio();
    }, [])

    return (
        <div className={styles.cases_grid}>
            <Filara />
            <RealEstate />
            <Ukie />
            <ClothingCo />
            <FinPro />
            {dynamicItems.map(i => (<CasePreview
                url={i.info.url}
                key={i._id}
                preview_description={i.info.preview_description}
                preview_img={i.info.preview_img}
                preview_title={i.info.preview_title}
            />))}
            <div className={styles.add_project}>
                <div className={styles.block} onClick={startWork}>
                    <div className={styles.vector} />
                    <div className={styles.vector} />
                </div>
                <h2>Ваш проект</h2>
            </div>
            <div className={styles.our_project}>
                <h2>Ваш проект</h2>
            </div>
        </div>
    );
};

export default CasesGrid;
