


import {h} from 'preact';
import LinksToSocialNetworks from "../../components/LinksToSocialNetworks/LinksToSocialNetworks";
import CasesGrid from "../../components/CasesGrid/CasesGrid";
import FeedbackSlider from "../../components/FeedbackSlider/FeedbackSlider";
import styles from "./style.scss"
import ReturnBack from "./ReturnBack";

const Success = () => {
    return (
        <main id="main-container" className={styles.wrapper} main-attr>
            <ReturnBack></ReturnBack>
            <h1>Отчет о самообследовании ООО «ИТ КОНСАЛТ»</h1>
            <p>В соответствии c пунктом 3 части 2 статьи 29 Федерального закона от 29 декабря 2012 г. № 273-ФЗ “Об
                образовании в Российской Федерации” и Приказа Министерства образования и науки Российской Федерации от
                14 июня 2013 г. № 462 «Об утверждении Порядка проведения самообследования образовательной организацией»,
                Постановлением Правительства Российской Федерации от 10.07.2013 № 582 «Об утверждении Правил размещения
                на официальном сайте образовательной организации в информационно-телекоммуникационной сети «Интернет» и
                обновления информации об образовательной организации» в ООО «ИТ КОНСАЛТ» ежегодно проводится
                самообследование.</p>
            <p>Целями проведения самообследования являются обеспечение доступности и открытости информации о
                деятельности Института, а также подготовка отчета о результатах самообследования (далее – Отчет).</p>
            <p>В процессе самообследования проведена оценка образовательной деятельности, системы управления Института,
                содержания и качества подготовки обучающихся, организации учебного процесса, качества кадрового,
                учебно-методического, материально-технической базы, функционирования внутренней системы оценки качества
                образования, а также анализ показателей деятельности Организации, подлежащей самообследованию,
                устанавливаемых федеральным органом исполнительной власти, осуществляющим функции по выработке
                государственной политики и нормативно-правовому регулированию в сфере образования.</p>
            <p>Отчет о проведенном самообследовании доводится до сведения учредителей, всех сотрудников и размещается на
                официальном сайте.</p>

        </main>
    );
};

export default Success;
