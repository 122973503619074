import { h } from 'preact';
import styles from "./style.scss";

const ReviewCard = ({ name, company, review, avatar, date }) => {

  return (
    <div className={styles.carousel_card}>
      <div className={styles.carousel_card_header}>
        <div className={styles.header_left}>
          <img src={avatar} alt="feedback avatar" title="feedback avatar" />
            <div>
              <h3>{name}</h3>
              <p>{company}</p>
            </div>
        </div>
        <div className={styles.header_right}>
          <p>{date}</p>
        </div>
      </div>
      <img className={styles.quote_mob} src="/assets/homepage/quote.svg" alt="quote" title="quote" />
      <p className={styles.review_text_mob}>{review}</p>
    </div>
  )
};

ReviewCard.defaultProps = {
  name: "",
  company: "no company",
  review: "no text",
  avatar: '',
  date: 'no date',
}

export default ReviewCard;
