import {h} from 'preact';
import styles from "./style.scss"
import Request from '../../components/Request/Request';

const NotFound = () => {
  return (
    <main id="main-container" className={styles.wrapper}>
      <div>
        <div style={{background: 'rgba(255, 255, 255, .15)', height: '1px', width: '100%'}}></div>
      <h1 style={{padding: '30px 0 30px 0'}}>
       Специальный раздел: Сведения об образовательной организации
       </h1>
        <h1 style={{padding: '10px 0 30px 0'}}>
       Раздел: Образование
       </h1>
      </div>
      <div className={styles.request}>
      <iframe className={styles.iframe} src="/assets/program.pdf?v=3" ></iframe>
      </div>
      <div className={styles.request} style={{marginTop: '70px'}}>
                <Request />
            </div>
    </main>
  );
};

export default NotFound;
