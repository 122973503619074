import {h} from 'preact';
import LinksToSocialNetworks from "../../components/LinksToSocialNetworks/LinksToSocialNetworks";
import CasesGrid from "../../components/CasesGrid/CasesGrid";
import FeedbackSlider from "../../components/FeedbackSlider/FeedbackSlider";
import styles from "./style.scss"
import ReturnBack from "./ReturnBack";

const Success = () => {
  return (
      <main id="main-container" className={styles.wrapper} main-attr>
          <ReturnBack></ReturnBack>
        Раздел находится в стадии доработки
      </main>
  );
};

export default Success;
