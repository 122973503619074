import {h} from 'preact';
import LinksToSocialNetworks from "../../components/LinksToSocialNetworks/LinksToSocialNetworks";
import CasesGrid from "../../components/CasesGrid/CasesGrid";
import FeedbackSlider from "../../components/FeedbackSlider/FeedbackSlider";
import styles from "./style.scss"
import ReturnBack from "./ReturnBack";

const Success = () => {
  return (
      <main id="main-container" className={styles.wrapper} main-attr>
          <ReturnBack></ReturnBack>

          <h1>Финансово-хозяйственная деятельность
          </h1>
          <h2>2023 год</h2>
          <ul className={'pdfLinks'}>
              <li>
                  <a target="_blank" href={'/assets/documents/fin-otchet.pdf'}>Бухгалтерская (финансовая) отчетность 2023 год

                  </a>
              </li> <li>
                  <a target="_blank" href={'/assets/documents/declorazia.pdf'}>Налоговая декларация по налогу, уплачиваемому в связи с применением упрощенной системы налогообложения за 2023 год

                  </a>
              </li>
          </ul> <h2>Другие документы</h2>
          <ul className={'pdfLinks'}>
              <li>
                  <a target="_blank" href={'/assets/documents/1_plan-fxd-2024.pdf'}>План Финансово-хозяйственной деятельности на 2024г

                  </a>
              </li> <li>
                  <a target="_blank" href={'/assets/documents/2_informaciya-ob-obeme.pdf'}>Информация об объеме образовательной деятельности

                  </a>
              </li>
          </ul>
      </main>
  );
};

export default Success;
