import {h} from 'preact';
import LinksToSocialNetworks from "../../components/LinksToSocialNetworks/LinksToSocialNetworks";
import CasesGrid from "../../components/CasesGrid/CasesGrid";
import FeedbackSlider from "../../components/FeedbackSlider/FeedbackSlider";
import styles from "./style.scss"
import ReturnBack from "./ReturnBack";

const Success = () => {
  return (
      <main id="main-container" className={styles.wrapper} main-attr>
          <ReturnBack></ReturnBack>
          <h1>Вакантные места для приема (перевода) в ООО «ИТ КОНСАЛТ»</h1>
          <table border={1}>
              <thead>

              <tr>
                  <th rowSpan="2">№ п/п</th>
                  <th rowSpan="2" style="width: 50%;">Наименование образовательной программы</th>
                  <th colSpan="4">Количество вакантных мест для приема (перевода) за счет</th>
              </tr>
              <tr>
                  <th>бюджетных ассигнований федерального бюджета</th>
                  <th>бюджетных ассигнований бюджетов субъекта РФ</th>
                  <th>бюджетных ассигнований местных бюджетов</th>
                  <th>средств физических и (или) юридических лиц</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                  <td></td>
                  <td>ДОПОЛНИТЕЛЬНОЕ ПРОФЕССИОНАЛЬНОЕ ОБРАЗОВАНИЕ</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
              </tr>
              <tr>
                  <td></td>
                  <td>Повышение квалификации</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
              </tr>
              <tr>
                  <td>1</td>
                  <td>Дополнительная программа повышения квалификации «Веб-программирование»</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>5</td>
              </tr>
              </tbody>
          </table>
      </main>
  );
};

export default Success;
