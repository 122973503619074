import { doc } from './browser_globals';

const getElement = (query) => {
  return doc?.querySelector?.(query);
};

function init_slider(sel, navSel, infoSel, opts = {}, onChangeSlide) {
  let el = getElement(sel);
  if (!el) {
    console.warn('Slider was not found');
    return;
  }
  let slideInfo;

  if (!el) {
    console.warn('[our team-controller] not able to find slider by this selector: ', sel);
    return;
  }

  if (opts.prevNext) {
    slideInfo = getElement(infoSel)
  }

  try {
    const options = {
      container: sel,
      items: opts.items || 1,
      slideBy: 1,
      mouseDrag: true,
      loop: true,
      swipeAngle: false,
      speed: 400,
      gutter: opts.gutter || 0,
    };

    if(opts.prevNext) {
      options.prevButton = `${navSel  } .prev-slide`;
      options.nextButton = `${navSel  } .next-slide`;
    }else{
      options.controls = false;
    }

    if(opts.responsive){
      options.responsive = opts.responsive
    }

    if(opts.autoplay){
      options.autoplay = true;
      options.autoplayButton = false;
      options.autoplayTimeout = 2000;
      options.autoplayHoverPause = true;
    }

    const slider = tns(options);

    if(opts.prevNext) {
      slider.events.on('transitionEnd', (item) => {
        const index = item.displayIndex;
        if (slideInfo) {
          slideInfo.innerText = index < 10 ? `0${Math.ceil(index)}` : Math.ceil(index);
        }
        if (typeof onChangeSlide === 'function') {
          onChangeSlide(item);
        }
      });
    }

    return slider;
  } catch (e) {
    console.warn('INIT SLIDER ERROR ',sel, e)
  }
}

export default init_slider;
