import {h} from 'preact';
import LinksToSocialNetworks from "../../components/LinksToSocialNetworks/LinksToSocialNetworks";
import CasesGrid from "../../components/CasesGrid/CasesGrid";
import FeedbackSlider from "../../components/FeedbackSlider/FeedbackSlider";
import styles from "./style.scss"
import ReturnBack from "./ReturnBack";

const Success = () => {
  return (
      <main id="main-container" className={styles.wrapper} main-attr>
          <ReturnBack></ReturnBack>

          <h1>Документов</h1>
          <h2>Учредительные документы</h2>
          <ul className={'pdfLinks'}>
              <li>
                  <a target="_blank" href={'/assets/documents/uchreditel/1_svidetelstvo-inn.pdf'}>Свидетельство о
                      постановке на учет
                      российской организации в налоговом органе по месту ее нахождения
                  </a>
              </li>
              <li>
                  <a target="_blank" href={'/assets/documents/uchreditel/3_ustav.pdf'}>УСТАВ ООО "ИТ КОНСАЛТ"

                  </a>
              </li>
              <li>
                  <a target="_blank" href={'/assets/documents/uchreditel/2_svidetelstvo-egrn.pdf'}>Свидетельство о
                      государственной регистрации ООО "ИТ КОНСАЛТ"
                  </a>
              </li>
              <li>
                  <a target="_blank" href={'/assets/documents/uchreditel/4_vypiska.pdf'}>Выписка из реестра лицензий №
                      Л035-01276-61-00736842

                  </a>
              </li>
          </ul>
          <h2>Нормативные документы</h2>
          <ul className={'pdfLinks'}>
              <li>
                  <a target="_blank" href={'/assets/documents/_1_politika-konfedencialnosti.pdf'}>Политика
                      конфиденциальности
                  </a>
              </li>
              <li>
                  <a target="_blank" href={'/assets/documents/norm/2_pravila-vtr.pdf'}>Правила внутреннего трудового распорядка
                  </a>
              </li>
              <li>
                  <a target="_blank" href={'/assets/documents/norm/3_polozenie-o-formax-kontrolya.pdf'}>Положение о формах, периодичности и порядке текущего контроля успеваемости, промежуточной и итоговой аттестации обучающихся
                  </a>
              </li>          <li>
                  <a target="_blank" href={'/assets/documents/norm/4_polozenie-perevode-i-otcislenii.pdf'}>Положение о порядке и основаниях перевода, отчисления и восстановления обучающихся
                  </a>
              </li>   <li>
                  <a target="_blank" href={'/assets/documents/norm/5_polozenie-o-rezime.pdf'}>Положение о режиме занятий обучающихся
                  </a>
              </li><li>
                  <a target="_blank" href={'/assets/documents/norm/6_polozenie-o-biblioteke.pdf'}>Положение об электронной библиотеке
                  </a>
              </li><li>
                  <a target="_blank" href={'/assets/documents/norm/7_polozenie-nesoversennoletnie.pdf'}>Порядок оформления возникновения, приостановления и прекращения отношений с обучающимися и (или) родителями (законными представителями) несовершеннолетних обучающихся
                  </a>
              </li><li>
                  <a target="_blank" href={'/assets/documents/norm/8_pravila-rasporyadka.pdf'}>Правила внутреннего распорядка обучающихся
                  </a>
              </li><li>
                  <a target="_blank" href={'/assets/documents/norm/9_pravila-priema.pdf'}>Правила приема обучающихся
                  </a>
              </li>
          </ul>
          <h2>Другие документы</h2>
          <ul className={'pdfLinks'}>
              <li>
                  <a target="_blank" href={'/assets/documents/other/1_polozenie-o-pers-dannyx.pdf'}>Положение о защите персональных данных сотрудников и обучающихся
                  </a>
              </li><li>
                  <a target="_blank" href={'/assets/documents/other/2_polozenie-ucebnyi-process.pdf'}>Положение об организации учебного процесса
                  </a>
              </li><li>
                  <a target="_blank" href={'/assets/documents/other/3_pravila-povedeniya.pdf'}>Правила поведения для обучающихся
                  </a>
              </li>
              <li>
                  <a target="_blank" href={'/assets/documents/other/4_polozenie-ucebnyi-centr.pdf'}>Положение об Учебном центре
                  </a>
              </li><li>
                  <a target="_blank" href={'/assets/documents/other/5_polozenie-korrupciya.pdf'}>Положение об антикоррупционной политике
                  </a>
              </li><li>
                  <a target="_blank" href={'/assets/documents/other/6_polozenie-snizenie-stoimosti.pdf'}>Положение о снижении стоимости
                  </a>
              </li><li>
                  <a target="_blank" href={'/assets/documents/other/7_polozenie-distant.pdf'}>Положение о порядке организации электронного обучения с применением дистанционных образовательных технологий
                  </a>
              </li><li>
                  <a target="_blank" href={'/assets/documents/other/8_metodiceskie-rekomendacii.pdf'}>Методические рекомендации по использованию программы электронного обучения и дистанционных образовательных технологий
                  </a>
              </li><li>
                  <a target="_blank" href={'/assets/documents/other/9_polozenie-ob-ucete-rezulatatov.pdf'}>Положение о порядке индивидуального учета результатов освоения обучающимися образовательных программ
                  </a>
              </li><li>
                  <a target="_blank" href={'/assets/documents/other/10_polozenie-prof-etika.pdf'}>Положение о нормах профессиональной этики педагогических работников
                  </a>
              </li><li>
                  <a target="_blank" href={'/assets/documents/other/11_otcet-samoobsledovanie.pdf'}>Отчет о самообследовании за 2023г
                  </a>
              </li><li>
                  <a target="_blank" href={'/assets/documents/other/12_programma-razvitiya.pdf'}>Программа развития на период с 2024 по 2026 годы
                  </a>
              </li><li>
                  <a target="_blank" href={'/assets/documents/other/13_polozenie-o-sobranii.pdf'}>Положение об общем собрании работников
                  </a>
              </li><li>
                  <a target="_blank" href={'/assets/documents/other/14_polozenie-o-pedsovete.pdf'}>Положение о педагогическом совете
                  </a>
              </li><li>
                  <a target="_blank" href={'/assets/documents/other/15_polozenie-o-komissii.pdf'}>
                      Положение о Комиссии по урегулированию споров между участниками образовательных отношений
                  </a>
              </li><li>
                  <a target="_blank" href={'/assets/documents/other/16_polozenie-ob-arxive.pdf'}>
                      Положение о системе хранения и архивирования документов
                  </a>
              </li>
          </ul>

      </main>
  );
};

export default Success;
