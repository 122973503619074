


import {h} from 'preact';
import LinksToSocialNetworks from "../../components/LinksToSocialNetworks/LinksToSocialNetworks";
import CasesGrid from "../../components/CasesGrid/CasesGrid";
import FeedbackSlider from "../../components/FeedbackSlider/FeedbackSlider";
import styles from "./style.scss"
import ReturnBack from "./ReturnBack";

const Success = () => {
    return (
        <main id="main-container" className={styles.wrapper} main-attr>
            <ReturnBack></ReturnBack>
            <h1>Материально-техническое обеспечение и оснащенность образовательного процесса. Доступная среда</h1>
            <ul id={'specialUL'}>
                <li><strong>Специально оборудованные учебные кабинеты:</strong> не имеются</li>
                <li><strong>Объекты для проведения практических занятий, приспособленные для использования инвалидами и
                    лицами с ограниченными возможностями здоровья:</strong> не имеются
                </li>
                <li><strong>Библиотеки, приспособленные для использования инвалидами и лицами с ограниченными
                    возможностями здоровья:</strong> не имеются
                </li>
                <li><strong>Объекты спорта, приспособленные для использования инвалидами и лицами с ограниченными
                    возможностями здоровья:</strong> не имеются
                </li>
                <li><strong>Средства обучения и воспитания, приспособленные для использования инвалидами и лицами с
                    ограниченными возможностями здоровья:</strong> не имеются
                </li>
                <li><strong>Обеспечение беспрепятственного доступа в здания образовательной
                    организации:</strong> обеспечивается управляющей компанией здания, в котором располагается
                    образовательная организация
                </li>
                <li><strong>Специальные условия питания:</strong> питание обучающихся отсутствует</li>
                <li><strong>Наличие условий для беспрепятственного доступа в общежитие, интернат:</strong> общежитие и
                    интернат отсутствуют
                </li>
                <li><strong>Доступ к информационным системам и информационно-телекоммуникационным сетям, приспособленным
                    для использования инвалидами и лицами с ограниченными возможностями
                    здоровья:</strong> Высокоскоростная корпоративная вычислительная сеть обеспечивает доступ к
                    информационным системам и информационно-телекоммуникационным сетям обучающихся, в том числе для
                    использования инвалидами и лицами с ограниченными возможностями здоровья, бесплатно в период
                    действия договора об оказании платных образовательных услуг. В учебных аудиториях Образовательной
                    организации возможно подключение к беспроводной сети «wi-fi» для доступа к сети «Интернет». Доступ к
                    беспроводной сети «wi-fi» осуществляется с использованием логина и пароля.
                </li>
            </ul>

        </main>
    );
};

export default Success;
