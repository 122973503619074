
import {h} from 'preact';

const ReturnBack = () => {
    return (<div main-attr>
        <a href={'/info'} style={{fontSize: '20px', paddingBottom: '30px', marginTop: '-0px', display: 'block'}}>Вернуться к сведениям об образовательной организации</a>
        </div>
    );
};

export default ReturnBack;
