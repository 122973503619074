import {h} from 'preact';
const Text = ({children}) => children;
import { useLayoutEffect } from "preact/hooks";
import ReviewCard from "../ReviewCard/ReviewCard";
import init_slider from "../../helpers/slider";
import {root} from "../../helpers/browser_globals";
import styles from "./style.scss";

const FeedbackSlider = () => {

  useLayoutEffect(() => {
    const slider = init_slider('#feedback-carousel-container',
      '#slide_carousel',
      '#cur_slide',{
        gap: 15,
        items: 1,
        gutter: 20,
        center: true,
        forceSlidePos: true,
        prevNext: true,
      });
      root.test = slider;
  }, []);

  return <></>
  return (
    <div id="feedback-car-mobile" className={styles.feedback_car_mobile}>
      <h2 className={styles.title}><Text id='blocks.feedback_slider.title'>Отзывы наших клиентов</Text></h2>

      <div id="feedback-slider" className={styles.feedback}>
        <div id="feedback-carousel-container" className={styles.feedback_carousel_container}>
          <div className={styles.cases_carousel_item}>
            <ReviewCard
              name={<Text id='blocks.feedback_slider.card1.name'>Francois Holl</Text>}
              company={<Text id='blocks.feedback_slider.card1.company'>Co-Founder & CEO at Kiskadi</Text>}
              avatar="/assets/components/reviews/francois_holl.webp?width=112"
              date="01.04.2020"
              review={<Text id='blocks.feedback_slider.card1.review'>Мой стартап разрабатывает облачные CRM-решения. Мы хотели выпустить новую версию маркетингового модуля в течение двух месяцев. ITRUM успешно скооперировались с нашей бразильской командой и создали ключевую часть функционала уже в первый месяц работы.</Text>}
            />
          </div>
          <div className={styles.cases_carousel_item}>
            <ReviewCard
              name={<Text id='blocks.feedback_slider.card2.name'>Ricardo Matos</Text>}
              company={<Text id='blocks.feedback_slider.card2.company'>Head of Tech at AlfaConvert</Text>}
              avatar="/assets/components/reviews/ricardo_matos.webp?width=112"
              date="07.08.2018"
              review={<Text id='blocks.feedback_slider.card2.review'>ITRUM создали лендинг и веб-приложение для нашей AdTech-компании. Хороший код и достойное тестирование перед запуском. Также они давали нам качественную обратную связь и помогали развивать продуктовые идеи.</Text>}
            />
          </div>
          <div className={styles.cases_carousel_item}>
            <ReviewCard
              name={<Text id='blocks.feedback_slider.card3.name'>Dominic Hacking</Text>}
              company={<Text id='blocks.feedback_slider.card3.company'>Founder & Web Developer at Hax</Text>}
              avatar="/assets/components/reviews/dominic_hacking.webp?width=112"
              date="17.03.2019"
              review={<Text id='blocks.feedback_slider.card3.review'>Я работал с ITRUM над несколькими проектами. Вас ожидает сотрудничество с инициативным партнером, который предан своему делу, честен, трудолюбив и внимателен к деталям, что повышает эффективность бизнес-процессов клиента.</Text>}
            />
          </div>
          <div className={styles.cases_carousel_item}>
            <ReviewCard
              name={<Text id='blocks.feedback_slider.card4.name'>Ilyas Djeddou</Text>}
              company={<Text id='blocks.feedback_slider.card4.company'>CTO at SIX Travel</Text>}
              avatar="/assets/components/reviews/ilyas_djeddou.webp?width=112"
              date="13.02.2021"
              review={<Text id='blocks.feedback_slider.card4.review'>ITRUM разработали приложение для бронирования отелей, продемонстрировав качественный результат в обговоренные сроки. Стоит отметить эффективность их работы и готовность откликаться на идеи клиента. Это увлеченная команда, которая легко идет на контакт.</Text>}
            />
          </div>
          <div className={styles.cases_carousel_item}>
            <ReviewCard
              name={<Text id='blocks.feedback_slider.card5.name'>Kit Norman</Text>}
              company={<Text id='blocks.feedback_slider.card5.company'>Director at Mercury Digital Assets Ltd</Text>}
              avatar="/assets/components/reviews/kit_norm.webp?width=112"
              date="19.03.2023"
              review={<Text id='blocks.feedback_slider.card5.review'>Мы проработали с этими ребятами уже несколько месяцев, и собираемся продолжать сотрудничество как можно дольше. Они приятные в общении и предоставляют очень качественные услуги по разумным ценам.</Text>}
            />
          </div>
          <div className={styles.cases_carousel_item}>
            <ReviewCard
              name={<Text id='blocks.feedback_slider.card6.name'>EvaCodes</Text>}
              company={<Text id='blocks.feedback_slider.card6.company'>Project Manager at EvaCodes</Text>}
              avatar="/assets/components/reviews/avatar_default.webp?width=112"
              date="27.10.2019"
              review={<Text id='blocks.feedback_slider.card6.review'>ITRUM работают над внутренним проектом для HR-отдела нашей IT-компании. Проект направлен на систематизацию процессов обработки информации. Значительная часть работы уже завершена. Нам нравится качество и темп, коммуникация, высокий уровень технической подготовки и большой опыт разработчиков, а также соблюдение сроков и требований.</Text>}
            />
          </div>
        </div>
      </div>

      <div id="slide_carousel" className={styles.cases_carousel_slide_counter}>
        <img src="/assets/homepage/arrow-left.svg" alt="previous slide" title="previous slide" className={`prev-slide ${styles.prev_slide}`} />
        <span id="cur_slide" className={styles.cases_carousel_current_slide_mob}>01</span>
        <span>/</span>
        <span>06</span>
        <img src="/assets/homepage/arrow-right.svg" alt="next slide" title="next slide" className={`next-slide ${styles.next_slide}`} />
      </div>
    </div>
  );
};

export default FeedbackSlider;
