import { h } from "preact";
const Text = ({children}) => children;
import { useRef, useState } from "preact/hooks";
import { sendForm } from "../../../assets/dom";
import styles from "./styles.scss";

const isValidateData = (value) => {
  switch (value[0]) {
    case "full_name": {
      if (!(value[1] && /^[а-яА-ЯЁёa-zA-Z\s]+$/.test(value[1]))) {
        return true;
      }
      break;
    }
    case "phone": {
      if (value[0] === "phone" && value[1] && !/[0-9]/.test(value[1])) {
        return true;
      }
      break;
    }
    case "email": {
      if (
        !(value[1] && /^((\w|\.|\-)+)@(\w+){2,}\.(\w+){2,}$/.test(value[1]))
      ) {
        return true;
      }
      break;
    }
  }
};

const RequestForm = () => {
  const form = useRef();
  const file = useRef();
  const [errors, setErrors] = useState({});
  const [filesArr, setFilesArr] = useState([]);
  const registerPlaceholders = {
    first_name: "Имя*",
    phone: "Номер телефона",
    email: "Email*",
    about_project: "Комментарий"
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    location.href = "/success";

    return;
    const values = new FormData(form.current);
    const form_tags = [];
    const errors = {};

    for (let key of values.entries()) {
      if (key[0].includes("tag_")) {
        form_tags.push(key[0]);
      }
      const dataNotValid = isValidateData(key);
      if (dataNotValid) {
        errors[key[0]] = dataNotValid;
      }
    }

    if (Object.keys(errors).length !== 0) {
      return setErrors(errors);
    }

    const formData = new FormData();
    formData.append("full_name", values.get("full_name"));
    formData.append("phone", values.get("phone"));
    formData.append("email", values.get("email"));
    formData.append("about_project", values.get("about_project"));
    formData.append("tags", form_tags);
    formData.append("file[]", filesArr[0]);
    formData.append("file[]", filesArr[1]);
    formData.append("file[]", filesArr[2]);

    sendForm(
      "POST",
      "https://exceed-team.com/epapi/apply",
      formData,
      () => {
        location.href = "/success";
      },
      (e) => {
        console.log("error: ", e);
        location.href = "/success";

      },
      true
    );
  };

  const withErrors = (name) => {
    const result = [styles.formField];
    if (errors[name]) {
      result.push(styles.error);
    }
    return result.join(" ");
  };

  const handleChangeInput = (e) => {
    const name = e.target.getAttribute("name");
    delete errors[name];

    setErrors({ ...errors });
  };

  const handleFileInput = (e) => {
    const files = e.target.files;
    const filesArr = [];
    let numberFile = files.length;

    if (!!filesArr.length || files.length > 3) {
      numberFile = 3 - filesArr.length;
    }

    if (numberFile <= 0) {
      return;
    }

    for (let i = 0; i < numberFile; i++) {
      if (files[i].size < 10000000) {
        filesArr.push(files[i]);
      }
    }

    e.target.value = "";
    setFilesArr(filesArr);
  };

  const handleDeleteFile = (idx) => {
    filesArr.splice(idx, 1);
    setFilesArr([...filesArr]);
  };

  return (
    <form
      className={styles.requestForm}
      ref={form}
      onSubmit={handleSubmit}
      noValidate={true}
    >
      <div className={styles.formLine}>
        <input
          className={withErrors("full_name")}
          name="full_name"
          type="text"
          placeholder={registerPlaceholders.first_name}
          required={true}
          onChange={handleChangeInput}
        />

        <input
          className={withErrors("phone")}
          name="phone"
          type="tel"
          placeholder={registerPlaceholders.phone}
          onChange={handleChangeInput}
        />
      </div>
      <input
        className={withErrors("email")}
        type="email"
        name="email"
        placeholder={registerPlaceholders.email}
        required={true}
        onChange={handleChangeInput}
      />
      <textarea
        className={`${styles.formField} ${styles.textarea}`}
        name="about_project"
        placeholder={registerPlaceholders.about_project}
        onChange={handleChangeInput}
      />
      <div className={styles.tagsBlock}>
        <p>
          <Text id="blocks.tags_block.title">Меня интересует:</Text>
        </p>
        <div className={styles.tagsList}>
          <div className={styles.tag} id="CRM">
            <input
              type="checkbox"
              name="tag_crm"
              id="tag_crm"
              value="tag_crm"
            />
            <label className={styles.tag_label} htmlFor="tag_crm">
              <Text id="blocks.tags_block.tag_label1">Обучение веб разработке</Text>
            </label>
          </div>
          <div className={styles.tag} id="Mobile app">
            <input
              type="checkbox"
              name="tag_mobile_app"
              id="tag_mobile_app"
              value="tag_mobile_app"
            />
            <label className={styles.tag_label} htmlFor="tag_mobile_app">
              <Text id="blocks.tags_block.tag_label2">
                Обучение React
              </Text>
            </label>
          </div>
          <div className={styles.tag} id="Website">
            <input
              type="checkbox"
              name="tag_website"
              id="tag_website"
              value="tag_website"
            />
            <label className={styles.tag_label} htmlFor="tag_website">
              <Text id="blocks.tags_block.tag_label3">Обучение JAVA</Text>
            </label>
          </div>
          <div className={styles.tag} id="E-commerce">
            <input
              type="checkbox"
              name="tag_e_commerce"
              id="tag_e_commerce"
              value="tag_e_commerce"
            />
            <label className={styles.tag_label} htmlFor="tag_e_commerce">
              <Text id="blocks.tags_block.tag_label4">Обучение верстке</Text>
            </label>
          </div>
          <div className={styles.tag} id="Redesign">
            <input
              type="checkbox"
              name="tag_redesign"
              id="tag_redesign"
              value="tag_redesign"
            />
            <label className={styles.tag_label} htmlFor="tag_redesign">
              <Text id="blocks.tags_block.tag_label5">Обучение разработке мобильных приложений</Text>
            </label>
          </div>
          <div className={styles.tag} id="UI/UX design">
            <input
              type="checkbox"
              name="tag_design"
              id="tag_design"
              value="tag_design"
            />
            <label className={styles.tag_label} htmlFor="tag_design">
              <Text id="blocks.tags_block.tag_label6">Я хочу быть куратором</Text>
            </label>
          </div>
          {/* <div className={styles.tag} id="MVP">
            <input
              type="checkbox"
              name="tag_mvp"
              id="tag_mvp"
              value="tag_mvp"
            />
            <label className={styles.tag_label} htmlFor="tag_mvp">
              <Text id="blocks.tags_block.tag_label7">MVP</Text>
            </label>
          </div>
          <div className={styles.tag} id="Team extension">
            <input
              type="checkbox"
              name="tag_team_extension"
              id="tag_team_extension"
              value="tag_team_extension"
            />
            <label className={styles.tag_label} htmlFor="tag_team_extension">
              <Text id="blocks.tags_block.tag_label8">Расширение команды</Text>
            </label>
          </div>
          <div className={styles.tag} id="Other">
            <input
              type="checkbox"
              name="tag_other"
              id="tag_other"
              value="tag_other"
            />
            <label className={styles.tag_label} htmlFor="tag_other">
              <Text id="blocks.tags_block.tag_label9">Другое</Text>
            </label>
          </div> */}
        </div>
      </div>
      <div className={styles.fileBlock}>
        <div className={styles.file}>
          {!!filesArr.length &&
            filesArr.map(({ name }, idx) => (
              <div key={idx} className={styles.file_name}>
                <p>{name}</p>
                <img
                  src="/assets/components/header/close.svg"
                  name={name}
                  alt="delete  svg"
                  onClick={() => handleDeleteFile(idx)}
                />
              </div>
            ))}
        </div>
        {/* <label htmlFor="img-upload">
          <div className={styles.attachFile}>
            <input
              type="file"
              name="img-upload"
              id="img-upload"
              multiple
              onChange={handleFileInput}
              ref={file}
            />
            <img src="/assets/homepage/Paperclip.svg" alt="attach file" />
            <p>
              <Text id="blocks.upload_block.attachfile_text">
                Прикрепите файл
              </Text>
            </p>
          </div>
        </label> */}
        {/* <p>
          <Text id="blocks.upload_block.maxsize_text">
            Максимальный размер файла 10мб
          </Text>
        </p> */}
      </div>
      <button className={styles.sendRequest} onClick={() => {
      }}>
        <Text id="blocks.upload_block.button_sendRequest">Отправить!</Text>
      </button>
      <p className={styles.politics}>
        <Text id="blocks.upload_block.politics.main_text">
          Отправкой этого сообщения вы подтверждаете прочтение и согласие c
        </Text>{" "}
        <a href="/privacy" onClick={() => (location.href = "/privacy")}>
          <Text id="blocks.upload_block.politics.link">
            Политикой конфиденциальности
          </Text>
        </a>
      </p>
    </form>
  );
};

export default RequestForm;
