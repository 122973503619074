


import {h} from 'preact';
import LinksToSocialNetworks from "../../components/LinksToSocialNetworks/LinksToSocialNetworks";
import CasesGrid from "../../components/CasesGrid/CasesGrid";
import FeedbackSlider from "../../components/FeedbackSlider/FeedbackSlider";
import styles from "./style.scss"
import ReturnBack from "./ReturnBack";

const Success = () => {
    return (
        <main id="main-container" className={styles.wrapper} main-attr>
            <ReturnBack></ReturnBack>
            <h1>Отчет о стипендиях и мерах поддержки обучающихся ООО «ИТ КОНСАЛТ»</h1>
            <p>Информация о наличии и условиях предоставления обучающимся стипендий: выплата стипендий не
                предусмотрена</p>
            <p>Информация о мерах социальной поддержки: меры социальной поддержки для обучающихся не предусмотрены.</p>
            <p>Информация о трудоустройстве выпускников: трудоустройство не предоставляется.</p>
            <p>Иные виды материальной поддержки: обучающимся предоставляется возможность оплаты обучения в рассрочку
                (частями). </p>
            <p>Общежития и интернат: отсутствуют.</p>
        </main>
    );
};

export default Success;
